<template>
  <div>
    <v-card elevation="0" color="transparent">
      <campaign-header />
      <v-card-text class="pa-0">
        <v-data-table
          v-model="selectedCampaigns"
          show-select
          :headers="translatedTableHeaders"
          :items="items"
          :options.sync="options"
          :loading="isLoading"
          :mobile-breakpoint="0"
          :server-items-length="total"
          :hide-default-header="isMobile"
          hide-default-footer
          :items-per-page="options.itemsPerPage"
          :class="isMobile ? 'campaign-mobile-table' : ''"
          @page-count="getPageCount($event)"
        >
          <template #top>
            <campaign-header-actions
              :filter-list="filterList"
              :filters="filters"
              :options="options"
              :headers="headersComputed"
              @headers-updated="setComputedHeaders($event)"
              @fetch="debouncedFetchCampaigns()"
              @reset="resetFilters()"
              @remove-filter="removeActiveFilter($event)"
              @set-filters="setFilters($event)"
              @set-filter-list="setFilterList($event)"
              @change-sort-by="changeSortBy($event, 'sortBy')"
              @change-sort-desc="changeSortBy($event, 'sortDesc')"
            />
          </template>
          <template v-if="!isMobile" #header="{ props }">
            <tr>
              <th
                v-for="(header, index) in props.headers"
                :key="index"
                :style="{
                  height: 0,
                  'min-width': header.minWidth
                }"
              />
            </tr>
          </template>

          <template v-else #item="{ item, select, isSelected }">
            <mobile-row
              :item="item"
              :selected="isSelected"
              @select="select($event)"
              @edit="beforeEdit(item, GTM_EVENTS.CAMPAIGN_ACTION_TABLE_EDIT)"
              @activate="activate([item], GTM_EVENTS.CAMPAIGN_ACTION_TABLE_ACTIVATE)"
              @pause="pause([item], GTM_EVENTS.CAMPAIGN_ACTION_TABLE_PAUSE)"
              @archive="openArchiveDialog([item], GTM_EVENTS.CAMPAIGN_ACTION_TABLE_ARCHIVE)"
              @duplicate="duplicate(item, GTM_EVENTS.CAMPAIGN_ACTION_TABLE_DUPLICATE)"
              @statistics="statistics(GTM_EVENTS.CAMPAIGN_ACTION_TABLE_STATISTICS)"
            />
          </template>

          <template #item.status="{ item }">
            <campaign-new-status :campaign="item" />
          </template>

          <template #item.name="{ item }">
            <div class="d-flex flex-column align-start">
              <div class="d-flex align-center">
                <span class="secondary-darken--text text-caption"># {{ item.id }}</span>
                <v-tooltip v-if="item.flat_deal_application" top>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="global-cursor-pointer ml-3"
                      color="black"
                      size="16"
                      v-bind="attrs"
                      v-on="on"
                    >
                      $cart
                    </v-icon>
                  </template>
                  <span>{{ $t('campaign.your_direct_campaign') }}</span>
                </v-tooltip>
              </div>
              <router-link class="primary--text" :to="{ name: routeNames.CAMPAIGN, params: { id: item.id } }">
                <span class="global-line-clamp">
                  {{ item.name }}
                </span>
              </router-link>
            </div>
          </template>

          <template #item.latestApproval="{ item }">
            <custom-chip-status
              small
              label
              :value="item.latest_approval.status"
              :settings="approvalStatusTypes"
            />
          </template>

          <template #item.adFormat="{ item }">
            {{ $t(`main.ad_format_types.${item.ad.ad_format_type}`) }}
          </template>

          <template #item.pricingModel="{ item }">
            <custom-chip-status
              small
              label
              outlined
              :value="item.pricing_model"
              :settings="pricingModels"
            />
          </template>

          <template #item.price="{ item }">
            <div v-if="item.price">
              <span>$ {{ priceByDefault(item.price) }}</span>
              <v-icon v-if="item.price < item.recommended_price && !item.flat_deal_application" class="error--text ml-1" size="10">
                $arrow-with-stick
              </v-icon>
            </div>
          </template>

          <template #item.reservedBalance="{ item }">
            <span> $ {{ toFixedByDefault(item.reservedBalance) }} </span>
          </template>

          <template #item.campaignGroupId="{ item }">
            {{ (item.campaign_group && item.campaign_group.name) || '-' }}
          </template>

          <template #item.actions="{ item }">
            <div class="d-flex justify-end">
              <campaign-actions
                :campaign="item"
                @activate="activate([item], GTM_EVENTS.CAMPAIGN_ACTION_TABLE_ACTIVATE)"
                @pause="pause([item], GTM_EVENTS.CAMPAIGN_ACTION_TABLE_PAUSE)"
                @archive="openArchiveDialog([item], GTM_EVENTS.CAMPAIGN_ACTION_TABLE_ARCHIVE)"
                @edit="beforeEdit(item, GTM_EVENTS.CAMPAIGN_ACTION_TABLE_EDIT)"
                @duplicate="duplicate(item, GTM_EVENTS.CAMPAIGN_ACTION_TABLE_DUPLICATE)"
                @statistics="statistics(GTM_EVENTS.CAMPAIGN_ACTION_TABLE_STATISTICS)"
              />
            </div>
          </template>

          <template #footer>
            <data-table-footer
              :items-per-page="options.itemsPerPage"
              :current-page="options.page"
              :page-count="options.pageCount"
              @change-items-per-page="changeItemsPerPage($event)"
              @change-current-page="changeCurrentPage($event)"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-expand-transition>
      <multiple-actions
        v-if="!!selectedCampaigns.length"
        :campaigns="selectedCampaigns"
        @activate="activate(selectedCampaigns, GTM_EVENTS.CAMPAIGN_ACTION_TABLE_BANNER_ACTIVATE)"
        @pause="pause(selectedCampaigns, GTM_EVENTS.CAMPAIGN_ACTION_TABLE_BANNER_PAUSE)"
        @archive="openArchiveDialog(selectedCampaigns, GTM_EVENTS.CAMPAIGN_ACTION_TABLE_BANNER_ARCHIVE)"
        @set-group="openSetGroupDialog(selectedCampaigns)"
        @duplicate-campaign="duplicate(selectedCampaigns, GTM_EVENTS.CAMPAIGN_ACTION_TABLE_BANNER_DUPLICATE)"
        @clear="selectedCampaigns = []"
      />
    </v-expand-transition>

    <campaign-dialog
      v-if="dialogIsShown"
      :campaign-config-form="campaignConfigForm"
      @close-dialog="dialogIsShown = false"
      @archive-campaign="archiveCampaign()"
      @set-group-campaign="setGroupCampaign()"
    >
      <template v-if="campaignConfigForm.selectField">
        <campaign-group-select v-model="selectedCampaigns.campaignGroupId" />
      </template>

      <template v-else>
        {{ $t(campaignConfigForm.message) }}
      </template>
    </campaign-dialog>

    <campaign-alert ref="campingAlert" :alert-type="alertType" @confirm="onAlertConfirm()" />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import DataTableFooter from '@clickadilla/components/ui/DataTableFooter.vue'
  import CampaignAlert from '@clickadilla/components/modals/CampaignAlert.vue'
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import { priceByDefault } from '@clickadilla/components/utils/price-by-default.js'
  import debounce from 'debounce'
  import { pricingModelTypes } from '@clickadilla/components/constants/pricing-model-types.js'
  import alertsTypes from '@clickadilla/components/constants/campings-alerts-types.js'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import CampaignGroupSelect from '@/components/Campaign/CampaignGroupSelect.vue'
  import CampaignNewStatus from '@/components/Campaign/CampaignNewStatus.vue'
  import CampaignActions from '@/components/CampaignActions.vue'
  import MobileRow from '@/components/Campaign/MobileRow.vue'
  import MultipleActions from '@/components/Campaign/MultipleActions.vue'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'
  import campaignsRepository from '@/services/repositories/campaigns-repository.js'
  import campaignGroupsRepository from '@/services/repositories/campaign-groups-repository.js'
  import CampaignDialog from '@/components/Campaign/CampaignDialog.vue'
  import routeNames from '@/types/route-names.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import handleErrors from '@/services/handleErrors.js'
  import CampaignHeader from '@/components/Campaign/CampaignHeader/CampaignHeader.vue'
  import CampaignHeaderActions from '@/components/Campaign/CampaignHeader/CampaignHeaderActions.vue'

  const { CAMPAIGN_ACTIONS } = GTM_EVENTS_CATEGORY

  export default {
    name: 'CampaignsTable',
    components: {
      CampaignHeaderActions,
      CampaignHeader,
      CustomChipStatus,
      CampaignGroupSelect,
      CampaignNewStatus,
      CampaignActions,
      MobileRow,
      MultipleActions,
      DataTableFooter,
      CampaignDialog,
      CampaignAlert
    },
    data() {
      return {
        debouncedFetchCampaigns: null,
        selectedCampaigns: [],
        headers: [],
        items: [],
        filterList: [
          {
            key: 'adFormat',
            name: 'main.ad_format',
            value: ''
          },
          {
            key: 'pricingModel',
            name: 'campaign.pricing_model',
            value: ''
          },
          {
            key: 'latestApproval',
            name: 'main.approval_status',
            value: ''
          },
          {
            key: 'campaignGroupId',
            name: 'campaign.campaign_group',
            value: null
          }
        ],
        filters: {
          reservedBalance: '',
          commonSearch: '',
          id: null,
          name: '',
          status: null,
          adFormat: '',
          pricingModel: '',
          latestApproval: '',
          campaignGroupId: null,
          limits: null
        },
        additionalHeaders: [
          {
            value: 'id',
            data: 'id',
            searchable: true
          },
          {
            data: 'limit_exceeded_name',
            value: 'limits',
            searchable: true,
            filters: true
          }
        ],
        options: {
          sortBy: [],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 15,
          pageCount: 0
        },
        isLoading: false,
        dataDialog: [],
        total: 0,
        dialogIsShown: false,
        campaignConfigForm: {},
        editCandidate: null,
        startCandidate: null,
        alertType: alertsTypes.BEFORE_EDIT,
        GTM_EVENTS
      }
    },
    computed: {
      routeNames() {
        return routeNames
      },
      ...mapState('settings', ['adNetwork', 'locale']),
      ...mapState('campaigns', ['pricingModels']),
      ...mapGetters('approvables', ['approvalStatusTypes']),
      headersComputed: {
        get() {
          return [
            {
              value: 'name',
              text: 'main.name',
              minWidth: '200px',
              searchable: true,
              class: 'black--text'
            },
            {
              value: 'actions',
              text: 'main.actions',
              width: '170px',
              sortable: false,
              class: 'black--text'
            },
            {
              value: 'status',
              text: 'main.status',
              name: 'status',
              minWidth: '100px',
              class: 'black--text'
            },
            {
              value: 'price',
              text: 'main.price',
              additionalText: ', $',
              minWidth: '130px',
              class: 'black--text'
            },
            {
              value: 'adFormat',
              data: 'ad_format',
              text: 'main.ad_format',
              minWidth: '185px',
              class: 'black--text'
            },
            {
              value: 'pricingModel',
              data: 'pricing_model',
              text: 'campaign.pricing_model',
              minWidth: '160px',
              class: 'black--text'
            },
            {
              value: 'latestApproval',
              data: 'approval_status',
              name: 'latestApproval.status',
              text: 'main.approval_status',
              minWidth: '150px',
              sortable: false,
              class: 'black--text'
            },
            {
              value: 'campaignGroupId',
              data: 'campaign_group_id',
              text: 'campaign.campaign_group',
              minWidth: '185px',
              class: 'black--text'
            }
          ]
        },
        set(value) {
          this.headers = [...value]
        }
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      breakpoint() {
        return this.$vuetify.breakpoint
      },
      campaignsIds() {
        return (campaigns) => campaigns.map((campaign) => campaign.id)
      },
      activeFilters() {
        return this.filterList.filter((filter) => filter.value)
      },
      archiveWidgetId() {
        const {
          archive_campaign_ru_widget_id: widgetIdRus,
          archive_campaign_en_widget_id: widgetIdEn
        } = this.adNetwork.settings.displayPopup.getsitecontrol
        return (this.locale !== 'ru' && widgetIdRus) ? widgetIdEn : widgetIdRus
      },
      translatedTableHeaders() {
        return this.headers.map((item) => ({
          ...item,
          text: `${this.$t(item.text)}${item?.additionalText ?? ''}`
        }))
      }
    },
    watch: {
      filters: {
        deep: true,
        handler() {
          this.options.page = 1
          this.debouncedFetchCampaigns()
        }
      },
      filterList: {
        deep: true,
        handler() {
          this.options.page = 1
          this.debouncedFetchCampaigns()
        }
      },
      options: {
        deep: true,
        handler() {
          this.debouncedFetchCampaigns()
        }
      }
    },
    created() {
      this.headers = [...this.headersComputed]
      const { query } = this.$route
      if ('status' in query) {
        this.filters.status = query.status
      }
      if ('statusApproval' in query) {
        this.filters.latestApproval = query.statusApproval
      }

      this.debouncedFetchCampaigns = debounce(() => {
        this.fetchTableCampaigns()
      }, 500)
    },
    methods: {
      changeSortBy(val, key) {
        this.options[key] = val
        if (key !== 'page') {
          this.options.page = 1
        }
        this.debouncedFetchCampaigns()
      },
      archiveCampaign() {
        this.archive(this.campaignConfigForm.payload)
        this.dialogIsShown = false
        if (this.campaignConfigForm.payload.length === 1 && this.archiveWidgetId) {
          this.$gsc.setParams({
            post_campaign_id: this.campaignConfigForm.payload[0].id
          })
          this.$gsc.openWidget(this.archiveWidgetId)
        }
      },
      setGroupCampaign() {
        this.addCampaignsToGroup({
          campaignsIds: this.selectedCampaigns.map((campaign) => campaign.id),
          campaignGroupId: this.selectedCampaigns.campaignGroupId
        })
        this.dialogIsShown = false
      },
      toFixedByDefault,
      priceByDefault,
      openArchiveDialog(campaigns, gtmEvent) {
        gtmPush({ event: gtmEvent, event_category: CAMPAIGN_ACTIONS })
        this.dialogIsShown = true
        this.campaignConfigForm = {
          title: 'campaign.archive_campaign',
          message: 'campaign.sure_archive_campaign',
          payload: campaigns,
          eventName: 'archive-campaign'
        }
      },
      openSetGroupDialog(campaign) {
        this.dialogIsShown = true
        this.campaignConfigForm = {
          title: 'campaign.set_group_campaign',
          payload: campaign,
          selectField: true,
          eventName: 'set-group-campaign'
        }
      },
      async fetchTableCampaigns() {
        this.isLoading = true
        try {
          const defaultSort = this.options.sortBy.length === 0 ? ['id'] : this.options.sortBy

          const filters = this.activeFilters.reduce((acc, val) => {
            const { key, value } = val
            if (value.value) {
              acc[key] = value.value
            }
            if (value.id) {
              acc[key] = value.id
            }
            return acc
          }, this.filters)

          const header = [...this.additionalHeaders, ...this.headers]

          const requestBody = {
            headers: header,
            options: { ...this.options, sortBy: defaultSort },
            draw: this.options.page,
            search: filters
          }

          const response = await campaignsRepository.campaignsTable(requestBody)

          this.items = response.items
          this.total = response.totalItemsCount
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      async fetchAction(actionType, ids) {
        this.isLoading = true
        try {
          await campaignsRepository[actionType]({ ids })
          await this.fetchTableCampaigns()
          this.selectedCampaigns = []
        } catch (error) {
          const message = error.response?.data?.message
            ? error.response?.data?.message
            : error.message

          this.$showErrorNotification(message)
        }
        this.isLoading = false
      },
      activate(campaigns, gtmEvent) {
        gtmPush({ event: gtmEvent, event_category: CAMPAIGN_ACTIONS })
        const needShow = this.checkIfNeedShowCampaignAlert(campaigns)

        if (needShow) {
          this.startCandidate = campaigns
          this.alertType = alertsTypes.BEFORE_SAVE
          this.$refs.campingAlert.open()
          return
        }

        this.fetchAction('activate', this.campaignsIds(campaigns))
      },
      checkIfNeedShowCampaignAlert(campaigns) {
        return campaigns.some((campaign) => [pricingModelTypes.CPM_GOAL, pricingModelTypes.CPC_GOAL].includes(campaign.pricing_model))
      },
      pause(campaigns, gtmEvent) {
        gtmPush({ event: gtmEvent, event_category: CAMPAIGN_ACTIONS })
        this.fetchAction('pause', this.campaignsIds(campaigns))
      },
      archive(campaigns) {
        this.fetchAction('delete', this.campaignsIds(campaigns))
      },
      beforeEdit(campaign, gtmEvent) {
        gtmPush({ event: gtmEvent, event_category: CAMPAIGN_ACTIONS })
        const isTestingStatus = campaign.cpa_status === 'testing'
        const isCpmGoalCompany = campaign.pricing_model === pricingModelTypes.CPM_GOAL

        if (isCpmGoalCompany && isTestingStatus) {
          this.editCandidate = campaign
          this.alertType = alertsTypes.BEFORE_EDIT
          this.$refs.campingAlert.open()
          return
        }

        this.edit(campaign)
      },
      edit(campaign) {
        this.$router.push({
          name: routeNames.EDIT_CAMPAIGN,
          params: {
            id: campaign?.id ?? this.editCandidate.id
          }
        })
      },
      duplicate(campaign, gtmEvent) {
        gtmPush({ event: gtmEvent, event_category: CAMPAIGN_ACTIONS })
        let campaignId

        if (Array.isArray(campaign)) {
          campaignId = campaign[0].id
        }

        this.$router.push({
          name: routeNames.DUPLICATE_CAMPAIGN,
          params: {
            id: campaign.id || campaignId
          }
        })
      },
      async addCampaignsToGroup(campaigns) {
        this.isLoading = true
        try {
          await campaignGroupsRepository.pushCampaignsToGroup(campaigns)
          await this.fetchTableCampaigns()
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      setFilters({ name, event }) {
        this.filters[name] = event
      },
      setFilterList({ name, event }) {
        const filter = this.filterList.find((item) => item.key === name)
        if (filter) {
          filter.value = event
        }
      },
      removeActiveFilter(filterKey) {
        const activeFilter = this.filterList.find((filter) => filter.key === filterKey)
        if (activeFilter) {
          activeFilter.value = null
          this.filters[activeFilter.key] = null
        }
      },
      resetFilters() {
        Object.keys(this.filters).forEach((key) => {
          this.filters[key] = null
        })
        this.filterList.map((filter) => {
          filter.value = null
          return filter
        })
        this.resetTable()
      },
      resetTable() {
        this.options.sortBy = []
        this.options.sortDesc = [true]
        this.options.page = 1
      },
      getPageCount(event) {
        this.options.pageCount = event
      },
      changeCurrentPage(event) {
        this.options.page = event
        this.debouncedFetchCampaigns()
      },
      changeItemsPerPage(event) {
        this.options.itemsPerPage = event
        this.debouncedFetchCampaigns()
      },
      onAlertConfirm() {
        if (this.alertType === alertsTypes.BEFORE_EDIT) {
          this.edit(this.editCandidate)
        }

        if (this.alertType === alertsTypes.BEFORE_START) {
          this.fetchAction('activate', this.campaignsIds(this.startCandidate))
        }
      },
      statistics(gtmEvent) {
        gtmPush({ event: gtmEvent, event_category: CAMPAIGN_ACTIONS })
      },
      setComputedHeaders(computedHeaders) {
        this.headers = [...computedHeaders]
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .text-start {
    padding: 8px 15px;
  }
  ::v-deep tr > td + td {
    padding: 8px 10px;
  }
  ::v-deep tbody tr{
    height: 76px;
  }
  ::v-deep thead tr th{
    height: unset !important;
    padding-top: 32px !important;
    padding-bottom: 12px !important;
  }
  .width-225 {
    width: 225px;
  }
  ::v-deep .v-text-field .v-input__slot{
    min-height: 28px !important;
  }

  ::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 32px;
  }
  ::v-deep .v-data-table__wrapper{
    padding-inline: 20px;
  }
  ::v-deep .campaign-mobile-table .v-data-table__wrapper{
    padding-inline: 0;
  }
</style>
